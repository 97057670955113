<template>
	<div class="lump" :style="lumpBg">
		<img class="title" src="@/assets/images/newyear/active-title.png" alt="">
		<div class="content">
			<p class="item mb">1、活动日期<span class="time">2022-01-28</span>日至<span class="time">2022-02-11</span>日，且本活动仅在此时间内有效；</p>
			<p class="item">2、活动期间内充值到指定金额即可领取丰厚奖励。奖励自动发放</p>
			<div class="total-num">
				<div class="column">
					<p class="p-1">累计充值钻石</p>
					<p class="p-2">奖励1</p>
					<p class="p-3">奖励2</p>
				</div>
				<div v-for="(item, index) in rewardList" :key="index" class="column blod">
					<p class="p-1 red">{{item.recharge}}</p>
					<p class="p-2">视频卡X{{item.card}}</p>
					<p class="p-3" :class="index == 0? 'fff':''">{{item.jewel}}赠送钻石</p>
				</div>
			</div>
			<p class="item mb">3、充值累计奖励活动仅计算活动期间内的充值钻石，活动期间外充值钻石、赠送钻石和购买VIP将不计入充值累计。</p>
			<p class="item">4、活动结束后排行榜前三可获得新春礼物奖励</p>
			<div class="total-no">
				<div class="column">
					<p class="p-1">名次</p>
					<p class="p-2">奖励1</p>
					<p class="p-3">奖励2</p>
					<p class="p-4">奖励3</p>
				</div>
				<div v-for="(item, index) in noList" :key="index" class="column blod">
					<p class="p-1 red">{{item.no}}</p>
					<p class="p-2">视频卡X{{item.video}}</p>
					<p class="p-3">语音卡X{{item.audio}}</p>
					<p class="p-4">福虎生威X{{item.gift}}</p>
				</div>
			</div>
			<p class="item">5、本次活动所有最终解释权归平台所有。</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lumpBg: {
					background: `#fd4749 url(${require("@/assets/images/newyear/bg.png")})`,
					backgroundSize: '100% auto',
					backgroundRepeat: 'no-repeat'
				},
				rewardList: [
					{ recharge: 490, card: 1, jewel: 0 },
					{ recharge: 990, card: 2, jewel: 50 },
					{ recharge: 1990, card: 3, jewel: 100 },
					{ recharge: 3990, card: 4, jewel: 200 },
					{ recharge: 6990, card: 5, jewel: 300 },
					{ recharge: 9990, card: 6, jewel: 500 },
					{ recharge: 19990, card: 8, jewel: 888 },
					{ recharge: 29990, card: 10, jewel: 1999 },
					{ recharge: 59990, card: 15, jewel: 2999 },
					{ recharge: 100000, card: 30, jewel: 4888 }
				],
				noList: [
					{ no: '第一名', video: 50, audio: 50, gift: 3 },
					{ no: '第二名', video: 30, audio: 30, gift: 2 },
					{ no: '第三名', video: 10, audio: 10, gift: 1 }
				]
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding 1.2rem 0 0.426666rem
		box-sizing border-box
		.title
			display block
			margin 0 auto
			width 6.533333rem
			height 1.333333rem
		.content
			margin 0.373333rem 0.373333rem
			padding 0.746666rem 0.54rem 0.46rem
			background #FFFFFF
			box-shadow 0px 8px 19px 1px rgba(194, 23, 26, 0.73)
			border-radius 0.48rem
			text-align left
			.item
				font-size 0.346666rem
				color #333333
				font-weight 500
				line-height 0.5rem
				.time
					margin 0 2px
					letter-spacing 1px
			.mb 
				margin-bottom 0.48rem
			.total-num
				margin 0.36rem 0 0.6rem
				.column
					display flex
					justify-content center
					align-items center
					border-top 1px solid #BFBFBF
					height 0.8rem
					line-height 0.8rem
					text-align center
					font-size 0.346666rem
					color #333333
					font-weight 500
					p 
						box-sizing border-box
						border-left 1px solid #BFBFBF
					.p-1 
						width 33%
					.p-2
						width 31%
					.p-3
						width 36%
						border-right 1px solid #BFBFBF
					.red
						color #FF2B2D
					.fff
						color #FFFFFF
				.column:nth-last-child(1)
					border-bottom 1px solid #BFBFBF
				.blod
					font-weight bold
			.total-no
				margin 0.3rem 0 0.6rem
				.column
					display flex
					justify-content center
					align-items center
					border-top 1px solid #BFBFBF
					height 0.8rem
					line-height 0.8rem
					text-align center
					p 
						box-sizing border-box
						border-left 1px solid #BFBFBF
					.p-1 
						width 22%
					.p-2
						width 29%
					.p-3
						width 29%
					.p-4
						width 29%
						border-right 1px solid #BFBFBF
					.red
						color #FF2B2D
				.column:nth-last-child(1)
					border-bottom 1px solid #BFBFBF
				.blod
					font-weight bold
</style>
